<script>
  export let src;
  let frame;

  $: {
    src;
    if (frame) {
      document.querySelector("iframe").src = src;
    }
  }
</script>

<iframe bind:this={frame} title="payment-widget" />

<style>
  iframe {
    width: 100%;
    height: 100%;
    min-height: 400px;
    border: 2px solid red;
  }
</style>
