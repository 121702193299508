<script>
  export let transactionData;
</script>

<form>
  <label>Name: <input bind:value={transactionData.name} type="text" /></label>
  <label
    >Surname: <input bind:value={transactionData.surname} type="text" /></label
  >
  <label>Email: <input bind:value={transactionData.email} type="text" /></label>
  <label>Nonce: <input bind:value={transactionData.nonce} type="text" /></label>
  <label
    >Merchant: <input
      bind:value={transactionData.merchant}
      type="text"
    /></label
  >
  <label
    >Amount: <input bind:value={transactionData.amount} type="text" /></label
  >
  <label
    >Currency: <input
      bind:value={transactionData.currency}
      type="text"
    /></label
  >
</form>

<style>
  form {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: 1rem;
  }

  label {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>
