<script>
  import Frame from "./Frame.svelte";
  import SrcForm from "./SrcForm.svelte";
  import { widgetUrl } from '../../../shared/config';

  let transactionData = {};
  let display = false;
  let src = "";

  function handleChange() {
    display = false;
    const params = Object.keys(transactionData).reduce((str, key) => {
      str += `${key}=${transactionData[key]}&`;
      return str;
    }, "");
    src = `${widgetUrl}#${params.slice(0, params.length - 1)}`;
    setTimeout(() => {
      display = true;
    }, 1000);
  }

  function handleDefault() {
    src = `${widgetUrl}#merchant=61924ed11f7e1bd2a8e9b77f&name=Tester&surname=Testerson&email=test@test.com&nonce=123&amount=0.3&currency=USD`;
    display = true;
  }
</script>

<main>
  <p class="src">{src}</p>
  <div class="content">
    <div class="controls">
      <SrcForm {transactionData} />
      <button on:click={handleChange}>OK</button>
      <button on:click={handleDefault}>Default</button>
    </div>
    <div class="frame">
      {#if display}
        <Frame bind:src />
      {/if}
    </div>
  </div>
</main>

<style>
  main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .controls {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 1000px) {
    .content {
      display: flex;
      flex-direction: column-reverse;
    }
    .frame {
      min-height: 400px;
    }
  }
</style>
