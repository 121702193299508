const tfOuput = require('./tf_output.json');

module.exports.apiUrl =
  "https://tybvzgm9d6.execute-api.ap-northeast-1.amazonaws.com";
module.exports.browserProxyAddress = `http://${tfOuput.web_proxy_private_ip.value}:8118`;
module.exports.dbCluster = tfOuput.documentdb_endpoint.value;
// TODO verify where to hold db credentials
module.exports.dbUsername = "aphadmin";
module.exports.dbPassword = "aphadmin1";

module.exports.widgetUrl = 'https://test.t-paytech.com';
